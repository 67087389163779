import React from "react";

import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import CenteredTextBlock from "../components/CenteredTextBlock";

const FourOFour = () => (
  <DefaultLayout title="404" beschreibung="404 - Page not found">
    <HeaderTitle inhalt="Page not found" />
    <GreenLead inhalt="<h2>404</h2>" />
    <CenteredTextBlock inhalt="<p>Page not found</p>" />
  </DefaultLayout>
);

export default FourOFour;
